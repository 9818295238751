import * as React from "react";
import { createRouterActions, RouterActionCreators } from "../actions/router.actions";
import { bindActionCreatorsToProps, connect } from "../store/componentBindings";
import { Meta } from "../components/Meta";

interface Props {
    actions?: {
        router: RouterActionCreators
    };
}

@connect(
    state => ({}),
    bindActionCreatorsToProps({
        router: createRouterActions()
    })
)

export class StateNoticePage extends React.Component<Props, any> {
    render() {
        return (
            <main className="misc">
                <Meta
                    title="State Notice | Landmark Home Warranty"
                    description="Landmark Home Warranty's state notice."
                    canonical="/state-notice"
                />
                <section className="section-intro misc">
                    <div className="container">
                        <div className="row intro-content">
                            <h1>Privacy Notice for California, Nebraska, Texas and Virginia Consumers</h1>
                            <p>www.landmarkhw.com  |  Updated 09/10/2024</p>
                        </div>
                    </div>
                </section>
                <section className="section-misc" style={{ backgroundColor: "rgb(251,251,251)" }}>
                    <div className="container">{/*<p className="title">Privacy Notice for California, Nebraska, Texas and Virginia Consumers</p>*/}
                        <p>This Privacy Notice for California, Nebraska, Texas and Virginia, Consumers (as may be amended from time to time, this “<u>Notice</u>”) is being provided by the Company in connection with the California Privacy Rights Act, Nebraska Data Privacy Act, Texas Data Privacy and Security Act and Virginia Consumer Data Protection Act (including any regulations that may be promulgated thereunder, and as each may be amended from time to time, “<u>Applicable Privacy Laws</u>”).  This Notice details important information regarding the collection, use and disclosure of personal information and personal data (as those terms are used interchangeably in this Notice and as defined under Applicable Privacy Laws, “<u>Personal Information</u>”) of natural persons who interact with the Company and who are residents of California, Nebraska, Texas or Virginia (“<u>Consumers</u>” or “<u>you</u>”).  This Notice supplements the information contained in the <a className="privacy" href="/privacy-policy"
                            onClick={e => {
                                e.preventDefault();
                                this.props.actions.router.push("/privacy-policy");
                                window.scrollTo(0, 0);
                            }}><u>Privacy Policy</u></a> (the “<u>Company Privacy Policy</u>”); provided that (a) the terms and conditions of the Company Privacy Policy intended to be applicable to you shall be applicable only to the extent such terms and conditions are allowable under Applicable Privacy Laws, and (b) in the event of a conflict between the terms and conditions of the Company Privacy Policy and this Notice, the terms and conditions of this Notice shall govern such conflict in all respects as relates to you to the extent this Notice is legally applicable to you and such conflict resolution is allowable under Applicable Privacy Laws.  Any terms used but not defined herein have the meanings given to such terms in the Company Privacy Policy or Applicable Privacy Laws, as applicable.</p>
                        <p>This Notice does not apply to (a) certain natural persons who are job applicants, employees, owners, directors, officers, medical staff members or contractors of the Company (or any emergency contact persons to such natural persons) solely to the extent otherwise applicable Personal Information is collected in connection with such role, (b) certain communications and transactions between the Company and natural persons acting as employees, owners, directors, officers or contractors of an entity and whose communication or transaction occurs solely in the business context and (c) certain other communications, transactions and information, as set forth in Applicable Privacy Laws.</p>
                        <p className="title"><u>Information We Collect</u></p>
                        <p>We collect Personal Information, as defined in Applicable Privacy Laws.  Personal Information can generally be described as information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a natural person or household.</p>
                        <p>We have collected the following categories of Personal Information from Consumers within the last twelve (12) months (and anticipate collecting these categories of Personal Information in the future): </p>
                        <ol style={{ marginLeft: "30px" }}>
                            <li><p><strong>Identifiers and identifying items</strong></p>
                                <p><em><u>Examples</u></em></p>
                                <p>Real name, signature, postal, billing or property address, telephone number, online identifier, internet protocol address, email address, account name, bank account number, credit card number, debit card number or other financial information</p>
                                <p><em><u>Sources (see categories below)</u></em></p>
                                <p>1, 2, 3, 4, 5</p>
                                <p><em><u>Purpose</u></em></p>
                                <p>Those purposes listed below under “Use of Personal Information”</p>
                            </li>
                            <li><p><strong>Commercial information</strong></p>
                                <p><em><u>Examples</u></em></p>
                                <p>Records of personal property, products or services purchased, obtained or considered</p>
                                <p><em><u>Sources (see categories below)</u></em></p>
                                <p>1, 3, 4, 5</p>
                                <p><em><u>Purpose</u></em></p>
                                <p>Those purposes listed below under “Use of Personal Information”</p>
                            </li>
                            <li><p><strong>Internet or other electronic network activity</strong></p>
                                <p><em><u>Examples</u></em></p>
                                <p>Browsing history, search history or other information on your interaction with our website, application or advertisement</p>
                                <p><em><u>Sources (see categories below)</u></em></p>
                                <p>2</p>
                                <p><em><u>Purpose</u></em></p>
                                <p>Those purposes listed below under “Use of Personal Information”</p>
                            </li>
                            <li><p><strong>Sensory data</strong></p>
                                <p><em><u>Examples</u></em></p>
                                <p>Call recordings used to verify certain transactions</p>
                                <p><em><u>Sources (see categories below)</u></em></p>
                                <p>1, 3</p>
                                <p><em><u>Purpose</u></em></p>
                                <p>Those purposes listed below under “Use of Personal Information”</p>
                            </li>
                            <li><p><strong>Inferences from Personal Information collected</strong></p>
                                <p><em><u>Examples</u></em></p>
                                <p>Your preferences or likelihood of interest in certain of our services</p>
                                <p><em><u>Sources (see categories below)</u></em></p>
                                <p>2, 4</p>
                                <p><em><u>Purpose</u></em></p>
                                <p>Those purposes listed below under “Use of Personal Information”</p>
                            </li>
                        </ol>
                        <p className="title"><u>Sources of Information Collected</u></p>
                        <p>We collect the Personal Information listed and described above from the following categories of sources:</p>
                        <ol style={{ marginLeft: "30px" }}>
                            <li><p>directly from Consumers and their agents.  For example, from forms you complete (whether on a Company Application or otherwise) or Products and Services you purchase;</p></li>
                            <li><p>indirectly from Consumers and their agents.  For example, from observing your actions, and collecting information requested by you, on Company Applications;</p></li>
                            <li><p>from third parties that we interact with in the course of providing products or services to Consumers.  For example, from service providers we contract with to fulfill work orders;</p></li>
                            <li><p>from third parties from whom we purchase Personal Information in order to provide a new or better service to you or prospective customers; and</p></li>
                            <li><p>from public sources, such as county and state tax and property records, government agencies and multiple listing services.</p></li>
                        </ol>
                        <p></p>
                        <p className="title"><u>Use of Personal Information</u></p>
                        <p>We may use or disclose the Personal Information we collect for one or more of the following commercial or business purposes: </p>
                        <ol style={{ marginLeft: "30px" }}>
                            <li><p>to allow you to register and create an account and profile on the Company Applications (and elsewhere);</p></li>
                            <li><p>to fulfill and facilitate any repair or replacement obligations owed to you in connection with any home service plan covering your property, including sending you maintenance communications;</p></li>
                            <li><p>to provide you access to Company Video Services in order for you to communicate with Video Experts who may provide assistance to you for your selected issue;</p></li>
                            <li><p>to deliver, provide and process payment for a Product or Service you have requested;</p></li>
                            <li><p>to enable you to provide us with feedback;</p></li>
                            <li><p>to administer promotions you enter into;</p></li>
                            <li><p>to allow your participation in our Communications Services (where available on the Company Applications);</p></li>
                            <li><p>to allow your participation in any surveys;</p></li>
                            <li><p>to prevent transactional fraud and other illegal activity and to protect the rights, property and safety of us, you and others;</p></li>
                            <li><p>to alert you to changes and updates in our Company Applications’ policies, Products or Services;</p></li>
                            <li><p>to send you communications (including targeted marketing and promotional offers) that may be of interest to you, such as emails or text messages about the Company and its affiliates and subsidiaries and their products or services;</p></li>
                            <li><p>to customize, measure and improve products, services, content and advertising, including generating internal reports about the use of our Company Applications;</p></li>
                            <li><p>to personalize your overall experience with Company;</p></li>
                            <li><p>to enforce our Terms of Use;</p></li>
                            <li><p>for testing, research and product development;</p></li>
                            <li><p>to respond to law enforcement and regulatory requests, or as required by applicable law, regulation or court order;</p></li>
                            <li><p>in connection with a merger, acquisition, divestiture, restructuring, reorganization, dissolution or other transaction involving some or all of our assets that include your information;</p></li>
                            <li><p>to provide you financial and investment-related information;</p></li>
                            <li><p>for certain other purposes disclosed at the time you provide your information; and</p></li>
                            <li><p>as otherwise set forth in this Notice or the Company Privacy Policy.</p></li>
                        </ol>
                        <p>We will not collect additional categories of Personal Information or use the Personal Information we collect for materially different, unrelated or incompatible purposes as compared to those described in this Notice without first, (a) with respect to collection, providing you notice of such collection and (b) with respect to use, providing you notice of such use and receiving your explicit consent to such use.  In addition, we do not currently collect or use “Sensitive Personal Information” (as that term is defined under Applicable Privacy Laws).</p>
                        <p className="title"><u>Retention of Personal Information</u></p>
                        <p>We will keep your Personal Information during the period of your relationship with us and, after that period ends, for as long as is necessary in connection with both our and your legal rights and obligations. This may mean that we keep some types of Personal Information for longer than others. We use the following criteria to determine how long to retain Personal Information: (a) our relationship with you, and the goods or services we provide to you; (b) any laws or regulations that we are required to follow; (c) whether we are or may be in a legal or other type of dispute with each other or any third party; (d) the type of information that we hold about you; (e) whether we are asked by you or any regulatory authority to keep your Personal Information for a valid reason, or if we may need to provide it for any legal reasons; and (f) technical considerations and feasibility, and the level of protections in place for your Personal Information.</p>
                        <p className="title"><u>Disclosing Personal Information for a Business Purpose</u></p>
                        <p>We disclose your Personal Information for a legitimate business purpose with the below categories of third parties.  The disclosure described in this section relates only to disclosure that is not disclosure for cross-context behavioral advertising (also referred to as “sharing” or “targeted advertising”); for information on sharing for cross-context behavioral advertising, please see the section below titled “Selling or Sharing Personal Information and Opting Out”.</p>
                        <ol style={{ marginLeft: "30px" }}>
                            <li><p>We disclose your Personal Information to service providers (also known as “processors” under certain Applicable Privacy Laws, and referred to herein as “<u>Service Providers</u>”), including advertisers, analytics providers, direct-mailing service providers and email-marketing service providers.</p></li>
                            <li><p>We disclose your Personal Information to the Company’s subsidiaries and affiliates (“<u>Affiliates</u>”).</p></li>
                            <li><p>We disclose your Personal Information to third parties to whom you or your agents instruct or authorize us to disclose your Personal Information in connection with the relationship (or prospective relationship) you have with the Company (“<u>Authorized Parties</u>”); for example, disclosure made in connection with real estate transactions where we disclose your Personal Information to real estate agents, title companies, attorneys or financial institutions.</p></li>
                        </ol>
                        <p>When we disclose Personal Information for a business purpose to a Service Provider, we enter into a written contract with the applicable Service Provider that, among other items, (a) describes the services to be provided, (b) requires such Service Provider to keep any Personal Information we have disclosed thereunder confidential and (c) prohibits such Service Provider from using such Personal Information for any purposes except to provide those services explicitly set forth in the contract.</p>
                        <p>In the preceding twelve (12) months, we have disclosed the following categories of Personal Information for a business purpose to the following third parties: </p>
                        <ol>
                            <li>
                                <p><strong>Identifiers and identifying items</strong></p>
                                <p><em><u>Categories of Third Parties</u></em>
                                    <div>Service Providers</div>
                                    <div>Affiliates</div>
                                    <div>Authorized Parties</div>
                                </p>
                            </li>
                            <li>
                                <p><strong>Commercial information</strong></p>
                                <p><em><u>Categories of Third Parties</u></em>
                                    <div>Service Providers</div>
                                    <div>Affiliates</div>
                                    <div>Authorized Parties</div>
                                </p>
                            </li>
                            <li>
                                <p><strong>Internet or other electronic network activity</strong></p>
                                <p><em><u>Categories of Third Parties</u></em>
                                    <div>Service Providers</div>
                                    <div>Affiliates</div>
                                </p>
                            </li>
                            <li>
                                <p><strong>Inferences from Personal Information collected</strong></p>
                                <p><em><u>Categories of Third Parties</u></em>
                                    <div>Service Providers</div>
                                    <div>Affiliates</div>
                                </p>
                            </li>
                        </ol>
                        <p className="title"><u>Selling or Sharing Personal Information and Opting Out</u></p>
                        <p>In the preceding twelve (12) months, we have shared Personal Information with the below third parties for cross-context behavioral advertising through online “cookies” and “tags” (for more information on those technologies, please see the section titled “Information Collected Through Technology” in the Company Privacy Policy).  Except as described in the preceding sentence, we have not otherwise sold or shared Personal Information in the previous twelve (12) months.  If you would like to opt out of the sharing of your Personal Information as described herein, please follow the instructions described in the section titled “Do Not Sell or Share” below.</p>
                        <ol>
                            <li>
                                <p><strong>Identifiers and identifying items</strong></p>
                                <p><em><u>Categories of Third Parties</u></em>
                                    <div>Advertising networks, including networks offered by Google and Meta (formerly known as Facebook)</div>
                                </p>
                            </li>
                            <li>
                                <p><strong>Internet or other electronic network activity</strong></p>
                                <p><em><u>Categories of Third Parties</u></em>
                                    <div>Advertising networks, including networks offered by Google and Meta (formerly known as Facebook)</div>
                                </p>
                            </li>
                        </ol>
                        <p><u className="title">Your Rights and Choices</u></p>
                        <p>Applicable Privacy Laws provide Consumers with specific rights regarding their Personal Information.  This section describes your rights (and certain of our duties) under Applicable Privacy Laws and explains how to exercise those rights.</p>
                        <p><u><em>General Duties</em></u>
                            <div>Under Applicable Privacy Laws, we have general duties that relate to your Personal Information.  For example, as further described in this Notice, and subject to certain exceptions, we must (a) allow you to confirm whether we are processing your Personal Information and access your Personal Information upon request, (b) delete your Personal Information upon request, (c) correct inaccurate Personal Information upon request, (d) not use Personal Information for purposes that are incompatible with the disclosed purpose for which such Personal Information was collected, (e) provide you information on how long we intend to retain your Personal Information, (f) inform you as to what Personal Information we sell or share and allow you to opt out of the sale and or sharing of your that Personal Information, (g) not retaliate against you for exercising your rights relating to Personal Information and (h) implement reasonable security procedures and practices to protect your Personal Information from unauthorized access, disclosure or use. </div></p>
                        <p><u><em>Access to Specific Information and Data Portability Rights</em></u>
                            <p>You have the right to request that we disclose certain information to you about our collection and use of your Personal Information.  Once we receive and confirm your Verified Request (as defined below), we will disclose to you: </p>
                            <ol style={{ marginLeft: "30px" }}>
                                <li><p>all categories of Personal Information we have collected about you since January 1, 2022 (unless you request a more recent date);</p></li>
                                <li><p>all categories of sources from which we have collected Personal Information about you during the applicable time period;</p></li>
                                <li><p>our business or commercial purpose for collecting, selling or disclosing, as applicable, such Personal Information;</p></li>
                                <li><p>the categories of third parties with whom we have shared any such Personal Information for a business purpose, organized in a list (which such list shall be separate from the list detailing the third parties to whom any such Personal Information was sold) that shows the categories of any such Personal Information we shared with each category of third party (or, if we have not shared any such Personal Information, we shall disclose the same);</p></li>
                                <li><p>the categories of third parties to whom we have sold any such Personal Information, organized in a list (which such list shall be separate from the list detailing the third parties to whom any such Personal Information was shared for a business purpose) that shows the categories of any such Personal Information sold to each category of third party (or, if we have not sold any such Personal Information, we shall disclose the same); and</p></li>
                                <li><p>the specific pieces of Personal Information we have collected about you (also called a data portability request) during the applicable time period.</p></li>
                            </ol>
                        </p>
                        <p><u><em>Deletion Request Rights</em></u>
                            <div>You have the right to request that we delete any of your Personal Information that we collected from you and retained, subject to certain exceptions.  Once we receive and confirm your Verified Request, we will remove (and direct our Service Providers to remove) such Personal Information from our records, unless an exception applies. </div></p>
                        <p>We may deny your deletion request to the extent that retaining the Personal Information is necessary for us or our Service Providers to: </p>
                        <ol style={{ marginLeft: "30px" }}>
                            <li><p>comply with federal, state, or local laws, rules, or regulations;</p></li>
                            <li><p>comply with a civil, criminal, or regulatory inquiry, investigation, subpoena, or summons by federal, state, local, or other governmental authorities;</p></li>
                            <li><p>investigate, establish, exercise, prepare for, or defend legal claims;</p></li>
                            <li><p>provide a product or service requested by you or perform a contract to which we are both party;</p></li>
                            <li><p>prevent, detect, protect against, or respond to security incidents, identity theft, fraud, harassment, malicious or deceptive activities, or any illegal activity;</p></li>
                            <li><p>engage in public or peer-reviewed scientific, historical or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, and meets certain other criteria;</p></li>
                            <li><p>identify and repair technical errors that impair existing or intended functionality; or</p></li>
                            <li><p>perform internal uses that are reasonably aligned with your expectations based on your business relationship with us.</p></li>
                        </ol>
                        <p>If it is necessary for us to retain certain information, we will inform you of the basis for such retention in response to your request.  For example, as a provider of home service plans, we may retain, for internal purposes, certain information relating to the service performed on your home.</p>
                        <p>You may also control certain uses of your Personal Information by opting out of marketing communications as described in the <a className="privacy" href="/privacy-policy"
                            onClick={e => {
                                e.preventDefault();
                                this.props.actions.router.push("/privacy-policy");
                                window.scrollTo(0, 0);
                            }}><u>“Choice and Opt Out”</u></a> section of the Company Privacy Policy.</p>
                        <p><u><em>Right to Correct</em></u>
                            <div>You have the right to request that we correct any Personal Information held about you by us that is inaccurate.  Once we receive and confirm your Verified Request (as defined below), we will use commercially reasonable efforts to correct any inaccurate Personal Information (and direct our Service Providers to correct such inaccurate Personal Information) and will inform you as to whether we have complied with your correction request.</div></p>
                        <p><u><em>Exercising Access, Data Portability, Deletion and Correction Rights</em></u>
                            <div>To exercise the access, data portability, deletion or correction rights described in this Notice, please submit a verifiable consumer request to us (a “<u>Verified Request</u>) by either: </div>
                            <ol style={{ marginLeft: "30px" }}>
                                <li><p>calling us at 1-844-521-1325; or</p></li>
                                <li><p>completing an online form available <a href="https://surveys.frontdoorhome.com/jfe/form/SV_6nBXh7oP8x8w29w?_gl=1*h90xqx*_ga*MzM3NzYyNDkxLjE2ODAxMjI0OTU.*_ga_FHCYV5KQ2Y*MTcxMzQ3MTgxMC42LjEuMTcxMzQ3Mjc5NS42MC4wLjA."><u>here</u></a></p></li>
                            </ol>
                        </p>    
                        <p>Only you, or someone legally authorized to act on your behalf (an “<u>Authorized Agent</u>”), may make a Verified Request related to your Personal Information.  To designate an Authorized Agent, please email us at <a href="mailto:privacy@frontdoor.com"><u>privacy@frontdoor.com</u></a> or write us at:</p>
                        <p>Legal Department<br /> Attn: Privacy Coordinator<br /> Frontdoor<br /> 3400 Players Club Parkway, Suite 300<br /> Memphis, TN 38125</p>
                        <p>A Verified Request must describe your request with sufficient detail such that the Verified Request allows us to properly understand, evaluate and respond to it.  In addition, your Verified Request must provide sufficient information that allows us to reasonably verify you are the Consumer about whom we collected Personal Information or an Authorized Agent of such Consumer, which such verification may include:</p>
                        <ol style={{ marginLeft: "30px" }}>
                            <li><p>residency verification;</p></li>
                            <li><p>verification of first name, last name, phone number, property address, unique customer number, phone number or email;</p></li>
                            <li><p>re-verification through phone, email or other communication mediums (in particular, if a request to delete is made online); and</p></li>
                            <li><p>with respect to Authorized Agents, (a) a signed authorization from the applicable Consumer evidencing such designation and (b) verification of such Consumer’s identity in accordance with the verification methods applicable to other Consumers.</p></li>
                        </ol>
                        <p>We cannot respond to a request to provide you with, or delete, Personal Information if we cannot verify (a) your identity, (b) a purported agent’s identity or authority to make the request or (c) that the requested Personal Information relates to you.  If we deny your Verified Request, we will inform you and explain the basis for such denial in writing.</p>
                        <p>We will only use Personal Information provided in a Verified Request to verify the requestor’s identity or authority to make the request.</p>
                        <p><u><em>Response Timing and Format</em></u>
                            <div>We endeavor to respond to each Verified Request within forty-five (45) days of our receipt of such Verified Request (and we will confirm receipt of such Verified Request within ten (10) days of our receipt thereof).  If we require more time (up to an additional forty-five (45) days), we will inform you of the reason and extension period in writing.  We will deliver our written response to your Verified Request by mail or electronically.</div></p>
                        <p>For data portability requests, we will select a format in which to provide your Personal Information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</p>
                        <p>We do not charge a fee to process or respond to a Verified Request unless such Verified Request is excessive, repetitive or manifestly unfounded.  If we determine that a Verified Request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
                        <p><u><em>Right to Appeal</em></u>
                            <div>In the event we deny a Verified Request, you have the right to seek an appeal of that decision.  To appeal a decision, please email <a href="mailto:privacy@frontdoor.com"><u>privacy@frontdoor.com</u></a> and include “Privacy Appeal” in the subject line.  Within sixty (60) days of our receipt of your appeal request, we will inform you of any action taken or not taken in response to your appeal.  If you remain unsatisfied with our decision, you may contact your state’s attorney general via the contact information provided <a href="https://www.naag.org/find-my-ag/"><u>here</u></a>. </div></p>
                        <p><u><em>Do Not Sell or Share</em></u>
                            <div>You have the right to opt out of the selling or sharing of your Personal Information.  In order to exercise that right, please visit <a title="Do Not Sell" href="https://submit-irm.trustarc.com/services/validation/520cfb62-d467-4bfe-b773-8a58015dc6d4"><u>Do Not Sell or Share My Personal Information</u></a>. Once you opt out of the selling or sharing of your Personal Information, we will promptly honor your request, including by refraining from sharing your Personal Information with third party ad networks for cross-context behavior advertising purposes.  Please note, due to technology limitations, (a) your opt-out as relates to online cross-context behavioral advertising can only be honored on an individual-browser basis (so you will need to opt out through each browser you use), (b) cookie elections are not always permanent, and it may be necessary to opt out from time to time (e.g., once per year), including if you clear your cookie cache and (c) your opt-out as relates to online cross-context behavioral advertising only applies with respect to the applicable brand’s domain.</div></p>
                        <p>Please note, if you opt out of sale or sharing we will no longer be able to deliver personalized online advertisements to you, and your experience within our platforms may be diminished.</p>
                        <p className="title"><u>Non-Discrimination</u></p>
                        <p>We will not discriminate against you for exercising any of your rights under Applicable Privacy Laws.  Unless permitted by Applicable Privacy Laws, we will not:</p>
                        <ol style={{ marginLeft: "30px" }}>
                            <li><p>deny you goods or services;</p></li>
                            <li><p>charge you different prices or rates for goods or services, including through granting discounts or other benefits or imposing penalties;</p></li>
                            <li><p>provide you a different level or quality of goods or services; or</p></li>
                            <li><p>suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</p></li>
                        </ol>
                        <p>Notwithstanding the foregoing, we may offer you certain financial incentives permitted by Applicable Privacy Laws that may result in different prices, rates or quality levels.  Any permitted financial incentive we offer will reasonably relate to your Personal Information’s value and contain written terms that describe the program’s material aspects.  Participation in a financial incentive program requires your prior opt in consent, which you may revoke at any time.  We do not currently offer any financial incentive programs relating to Personal Information.</p>
                        <p><u><em>Other Privacy Rights</em></u>
                            <div>Applicable Privacy Laws do not limit Consumers’ rights under existing privacy legislation or regulation.  As such, Consumers may request certain information about our disclosures of certain categories of Personal Information to third parties for direct marketing purposes.  All requests for such disclosure must be submitted to the Privacy Coordinator at the address set forth below.  </div></p>
                        <p className="title"><u>Changes to Our Notice</u></p>
                        <p>We reserve the right to amend this Notice at our discretion and at any time in accordance with applicable law.  When we make changes to this Notice, we will post the updated Notice on the Company Applications and update the “Effective Date” posted at the top of the Notice.  YOUR CONTINUED USE OF COMPANY APPLICATIONS FOLLOWING THE POSTING OF CHANGES CONSTITUTES YOUR ACCEPTANCE OF SUCH CHANGES.</p>
                        <p className="title"><u>Contact Information</u></p>
                        <p>If you have any questions or comments about this Notice, the ways in which the Company collects and uses your Personal Information or your choices and rights regarding such collection and use under Applicable Privacy Laws, please do not hesitate to contact us at:</p>
                        <p>Legal Department<br /> Attn: Privacy Coordinator<br /> Frontdoor<br /> 3400 Players Club Parkway, Suite 300<br /> Memphis, TN 38125</p>
                        <p><em>(Last updated September 10</em>
                            <em>, 2024)</em></p>
                        <p>&nbsp;</p>
                    </div>
                </section>
            </main>
        );
    }
}
